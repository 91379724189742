import {call, put, takeEvery, takeLatest, select} from 'redux-saga/effects' // Import debounce from redux-saga
import {searchFilterActions, selectIdHoSo} from './searchFilterSlice'
import { getListDirectPaymentRecord, getListHanMucConLai, getListMedicalRecord, postMedicalExpense} from './service'
import {handleError} from '_metronic/helpers'

function* handleGetListMedicalRecord({payload}) {
  try {
    const resultsListMedicalRecord = yield call(getListMedicalRecord, payload)
    if (resultsListMedicalRecord) {
      yield put({
        type: searchFilterActions.getListMedicalRecordSuccess.type,
        payload: resultsListMedicalRecord,
      })
      const ids = resultsListMedicalRecord?.results
        ?.filter((item) => item.hasOwnProperty('id'))
        .map((item) => item.id)
      yield put({
        type: searchFilterActions.setObjectPrevFowardId.type,
        payload: {
          page: payload?.page / payload?.items_per_page,
          items_per_page: payload?.items_per_page,
          listId: ids,
        },
      })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.getListMedicalRecordFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListDirectPaymentRecord({payload}) {
  try {
    const resultsListDirectPaymentRecord = yield call(getListDirectPaymentRecord, payload)
    if (resultsListDirectPaymentRecord) {
      yield put({
        type: searchFilterActions.getListDirectPaymentRecordSuccess.type,
        payload: resultsListDirectPaymentRecord,
      })
      const ids = resultsListDirectPaymentRecord?.results
      ?.filter((item) => item.hasOwnProperty('id'))
      .map((item) => item.id)
    yield put({
      type: searchFilterActions.setObjectPrevFowardIdTTTT.type,
      payload: {
        page: payload?.page / payload?.items_per_page,
        items_per_page: payload?.items_per_page,
        listId: ids,
      },
    })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.getListDirectPaymentRecordFailed.type,
    })
  }
}

function* handleGetListMedicalRecordPrevNextPage({payload}) {
  try {
    const resultsListMedicalRecordPrevNextPage = yield call(getListMedicalRecord, payload?.payload)
    if (resultsListMedicalRecordPrevNextPage) {
      const ids = resultsListMedicalRecordPrevNextPage?.results
        ?.filter((item) => item.hasOwnProperty('id'))
        .map((item) => item.id)
      yield put({
        type: searchFilterActions.setObjectPrevFowardId.type,
        payload: {
          page: resultsListMedicalRecordPrevNextPage?.page,
          items_per_page: payload?.payload?.items_per_page,
          listId: ids,
          currentProfileIndex: payload?.type === 'next' ? 0 : payload?.payload?.items_per_page - 1,
          idSelected: payload?.type === 'next' ? ids[0] : ids[payload?.payload?.items_per_page - 1],
          total: resultsListMedicalRecordPrevNextPage?.total,
        },
      })
      yield put({
        type: searchFilterActions.getListMedicalRecordPrevNextPageSuccess.type,
      })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.getListMedicalRecordPrevNextPageFailed.type,
    })
  }
}

function* handleGetListMedicalRecordPrevNextPageTTTT({payload}) {
  try {
    const resultsListMedicalRecordPrevNextPageTTTT = yield call(getListDirectPaymentRecord, payload?.payload)
    if (resultsListMedicalRecordPrevNextPageTTTT) {
      const ids = resultsListMedicalRecordPrevNextPageTTTT?.results
        ?.filter((item) => item.hasOwnProperty('id'))
        .map((item) => item.id)
      yield put({
        type: searchFilterActions.setObjectPrevFowardIdTTTT.type,
        payload: {
          page: resultsListMedicalRecordPrevNextPageTTTT?.page,
          items_per_page: payload?.payload?.items_per_page,
          listId: ids,
          currentProfileIndex: payload?.type === 'next' ? 0 : payload?.payload?.items_per_page - 1,
          idSelected: payload?.type === 'next' ? ids[0] : ids[payload?.payload?.items_per_page - 1],
          total: resultsListMedicalRecordPrevNextPageTTTT?.total,
        },
      })
      yield put({
        type: searchFilterActions.getListMedicalRecordPrevNextPageTTTTSuccess.type,
      })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.getListMedicalRecordPrevNextPageTTTTFailed.type,
    })
  }
}

function* handlePostMedicalExpense({payload}) {
  console.log('payload', payload)
  const idHoSo = yield select(selectIdHoSo)

  try {
    const resultPostMedicalExpense = yield call(postMedicalExpense, {
      data: payload,
      idHoSo,
    })
    if (resultPostMedicalExpense) {
      yield put({
        type: searchFilterActions.postMedicalExpenseSuccess.type,
        payload: resultPostMedicalExpense,
      })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.postMedicalExpenseFailed.type,
    })
  }
}

function* handleGetListHanMucConLai({payload}) {
  try {
    const resultGetListHanMucConLai = yield call(getListHanMucConLai, payload)

    if (resultGetListHanMucConLai) {
      yield put({
        type: searchFilterActions.getListHanMucConLaiSuccess.type,
        payload: resultGetListHanMucConLai,
      })
    }
  } catch (e) {
    yield put({
      type: searchFilterActions.getListHanMucConLaiFailed.type,
    })
  }
}

export default function* searchFilterSaga() {
  yield takeEvery(searchFilterActions.getListMedicalRecord.type, handleGetListMedicalRecord);
  yield takeEvery(searchFilterActions.getListDirectPaymentRecord.type, handleGetListDirectPaymentRecord);
  yield takeLatest(
    searchFilterActions.postMedicalExpense.type,
    handlePostMedicalExpense
  )
  yield takeEvery(
    searchFilterActions.getListMedicalRecordPrevNextPage.type,
    handleGetListMedicalRecordPrevNextPage
  )
  yield takeEvery(
    searchFilterActions.getListMedicalRecordPrevNextPageTTTT.type,
    handleGetListMedicalRecordPrevNextPageTTTT
  )
  yield takeEvery(
    searchFilterActions.getListHanMucConLai.type,
    handleGetListHanMucConLai
  )
}
