import {call, put, takeEvery} from 'redux-saga/effects'
import {categoryActions} from './categorySlice'
import {
  getListInsuranceCompany,
  getListCSKCB,
  getListProvince,
  getListDistrict,
  getListWard,
  getListLyDoTuChoi,
  getListLoaiFile,
  getListCauHinhLoaiFileHD,
  getListDanhMucThuoc,
  getListCauHinhDongChiTra,
  getListCskcbBaoLanh,
  getListLyDoPheDuyet,
} from './service'
import {handleError} from '_metronic/helpers'

function* handleGetListCSKCB({payload}) {
  try {
    const resultsListCSKCB = yield call(getListCSKCB, payload)
    if (resultsListCSKCB?.result) {
      const arrayTemp = resultsListCSKCB?.result?.map((item) => ({
        label: `${item.value || ''} - ${item.label || ''}`,
        value: item.value,
      }))
      arrayTemp.unshift({
        label: 'Tất cả',
        value: null,
      })
      yield put({
        type: categoryActions.getListCSKCBSuccess.type,
        payload: {...resultsListCSKCB, results: arrayTemp},
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCSKCBFailed.type,
    })
    handleError(e)
  }
}
function* handleGetListInsuranceCompany({payload}) {
  try {
    const resultsListInsuranceCompany = yield call(getListInsuranceCompany, payload)
    if (resultsListInsuranceCompany?.result) {
      const arrayTemp = resultsListInsuranceCompany.result
      yield put({
        type: categoryActions.getListInsuranceCompanySuccess.type,
        payload: {...resultsListInsuranceCompany, result: arrayTemp},
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListInsuranceCompanyFailed.type,
    })
  }
}
function* handleGetListProvince({payload}) {
  try {
    const resultListProvince = yield call(getListProvince, payload)
    if (resultListProvince) {
      const arrayTemp = resultListProvince.result
      // arrayTemp.unshift({
      //   label : "Tất cả",
      //   value : null
      // })
      yield put({
        type: categoryActions.getListProvinceSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListProvinceFailed.type,
    })
  }
}
function* handleGetListDistrict({payload}) {
  try {
    const resultListDistrict = yield call(getListDistrict, payload)
    if (resultListDistrict) {
      const arrayTemp = resultListDistrict.result
      // arrayTemp.unshift({
      //   label : "Tất cả",
      //   value : null
      // })
      yield put({
        type: categoryActions.getListDistrictSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListDistrictFailed.type,
    })
  }
}
function* handleGetListWard({payload}) {
  try {
    const resultListWard = yield call(getListWard, payload)
    if (resultListWard) {
      const arrayTemp = resultListWard.result
      yield put({
        type: categoryActions.getListWardSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListWardFailed.type,
    })
  }
}
function* handleGetListLyDoTuChoi({payload}) {
  try {
    const resultListLyDoTuChoi = yield call(getListLyDoTuChoi, payload)
    if (resultListLyDoTuChoi) {
      const arrayTemp = resultListLyDoTuChoi
      yield put({
        type: categoryActions.getListLyDoTuChoiSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLyDoTuChoiFailed.type,
    })
  }
}

function* handleGetListLoaiFile({payload}) {
  try {
    const resultListLoaiFile = yield call(getListLoaiFile, payload)
    if (resultListLoaiFile) {
      const arrayTemp = resultListLoaiFile
      yield put({
        type: categoryActions.getListLoaiFileSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLoaiFileFailed.type,
    })
  }
}

function* handleGetListCauHinhLoaiFileHopDong({payload}) {
  try {
    const resultListLoaiFile = yield call(getListCauHinhLoaiFileHD, payload)
    if (resultListLoaiFile) {
      const arrayTemp = resultListLoaiFile
      yield put({
        type: categoryActions.getListCauHinhLoaiFileHDSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCauHinhLoaiFileHDFailed.type,
    })
  }
}

function* handleGetListDanhMucThuoc({payload}) {
  try {
    const resultListDanhMucThuoc = yield call(getListDanhMucThuoc, payload)
    if (resultListDanhMucThuoc) {
      const arrayTemp = resultListDanhMucThuoc
      yield put({
        type: categoryActions.getListDanhMucThuocSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListDanhMucThuocFailed.type,
    })
  }
}
function* handleGetListCauHinhDongChiTra({payload}) {
  try {
    const resultListCauHinhDongChiTra = yield call(getListCauHinhDongChiTra, payload)
    if (resultListCauHinhDongChiTra) {
      const arrayTemp = resultListCauHinhDongChiTra
      yield put({
        type: categoryActions.getListCauHinhDongChiTraSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCauHinhDongChiTraFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListCskcbBaoLanh({payload}) {
  try {
    const resultListCskcbBaoLanh = yield call(getListCskcbBaoLanh, payload)
    if (resultListCskcbBaoLanh) {
      const arrayTemp = resultListCskcbBaoLanh
      yield put({
        type: categoryActions.getListCskcbBaoLanhSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCskcbBaoLanhFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListLyDoPheDuyet({payload}) {
  try {
    const resultListLyDoPheDuyet = yield call(getListLyDoPheDuyet, payload)
    if (resultListLyDoPheDuyet) {
      const arrayTemp = resultListLyDoPheDuyet
      yield put({
        type: categoryActions.getListLyDoPheDuyetSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLyDoPheDuyetFailed.type,
    })
    handleError(e)
  }
}
export default function* categorySaga() {
  yield takeEvery(categoryActions.getListCSKCB.type, handleGetListCSKCB)
  yield takeEvery(categoryActions.getListInsuranceCompany.type, handleGetListInsuranceCompany)
  yield takeEvery(categoryActions.getListProvince.type, handleGetListProvince)
  yield takeEvery(categoryActions.getListDistrict.type, handleGetListDistrict)
  yield takeEvery(categoryActions.getListWard.type, handleGetListWard)
  yield takeEvery(categoryActions.getListLyDoTuChoi.type, handleGetListLyDoTuChoi)
  yield takeEvery(categoryActions.getListLoaiFile.type, handleGetListLoaiFile)
  yield takeEvery(
    categoryActions.getListCauHinhLoaiFileHD.type,
    handleGetListCauHinhLoaiFileHopDong
  )
  yield takeEvery(categoryActions.getListDanhMucThuoc.type, handleGetListDanhMucThuoc)
  yield takeEvery(categoryActions.getListCauHinhDongChiTra.type, handleGetListCauHinhDongChiTra)
  yield takeEvery(categoryActions.getListCskcbBaoLanh.type, handleGetListCskcbBaoLanh)
  yield takeEvery(categoryActions.getListLyDoPheDuyet.type, handleGetListLyDoPheDuyet)
}
