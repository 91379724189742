import {api} from 'app/config/api'
import axios from 'axios'

export const getDanhSachCoSoKCBBaoLanh = (data) => {
  // console.log('data ds co so kcb bao lanh', data)
  const objectTemp = {
    ...data?.data,
    page: data?.data?.page / data?.data?.items_per_page,
    searchBaoLanh:
      data?.data?.searchBaoLanh?.value !== undefined
        ? data?.data?.searchBaoLanh?.value
        : null,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + `/${data?.idHopDong}/thong-tin-chi-phi/cskcb-bao-lanh` + objString)
      .then((results) => {
        // console.log('results', results)
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getDanhSachCoSoKCBKTTBH = (data) => {
  console.log('data ds co so kcb bao lanh', data)
  const objectTemp = {
    ...data?.data,
    page: data?.data?.page / data?.data?.items_per_page,
    searchKhongThanhToan:
      data?.data?.searchKhongThanhToan?.value !== undefined
        ? data?.data?.searchKhongThanhToan?.value
        : null,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + `/${data?.idHopDong}/thong-tin-chi-phi/cskcb-khong-thanh-toan` + objString)
      .then((results) => {
        console.log('results', results)
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    }
  }
}
