import {createSlice} from '@reduxjs/toolkit'
import {getListCauHinhLoaiFileHD} from './service'

const initialState = {
  ListCSKCB: [],
  ListInsuranceCompany: [],
  listProvince: [],
  idSelectedProvince: null,
  idSelectedDistrict: null,
  listDistrict: [],
  listWard: [],
  listLyDoTuChoi: [],
  listLoaiFile: [],
  listCauHinFilehHopDong: [],
  loading: false,
  loadingCauHinhDuyetHoSo: false,
  listCauHinhDuyetHoSo: [],
  totalPageCauHinhDuyetHoSo: null,
  objectSearchListCauHinhDuyetHoSo: {
    page: 0,
    items_per_page: 10,
  },
  listTenCanBo: [],
  listInsuranceCompanyWithFilter: [],
  filterListInsuranceCompany: {
    page: 0,
    items_per_page: 10,
    search: '',
  },
  totalLengthListInsuranceCompanyWithFilter: null,
  listDanhMucThuoc: [],
  objectSearchListDanhMucThuoc: {
    page: 0,
    items_per_page: 10,
    searchText:null,
  },
  loadingDanhSachDanhMucThuoc: false,
  totalPageDanhSachDanhMucThuoc: null,

  loadingCauHinhDongChiTra:false,
  objectSearchCauHinhDongChiTra: {
    page : 0,
    items_per_page :10
  },
  listCauHinhDongChiTra:[] ,
  totalPageCauHinhDongChiTra: null,
  listCskcbBaoLanh: [],
  totalCountCskcbAllBaoLanh: null,
  listLyDoPheDuyet: [],
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getListCSKCB(state, action) {
      state.loading = true
    },
    getListCSKCBSuccess(state, action) {
      state.ListCSKCB = action.payload.results
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListCSKCBFailed(state) {
      state.loading = false
      state.ListCSKCB = []
    },
    getListInsuranceCompany(state, action) {
      state.loading = true
    },
    getListInsuranceCompanySuccess(state, action) {
      state.ListInsuranceCompany = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListInsuranceCompanyFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    getListProvince(state) {
      state.loading = true
    },
    getListProvinceSuccess(state, action) {
      state.listProvince = action.payload
    },
    getListProvinceFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    setIdTinhThanh(state, action) {
      state.idSelectedProvince = action.payload
    },
    getListDistrict(state, action) {
      state.loading = true
    },
    getListDistrictSuccess(state, action) {
      state.listDistrict = action.payload
    },
    getListDistrictFailed(state) {
      state.loading = false
      state.listDistrict = []
    },
    setIdQuanHuyen(state, action) {
      state.idSelectedDistrict = action.payload
    },
    getListWard(state, action) {
      state.loading = true
    },
    getListWardSuccess(state, action) {
      state.listWard = action.payload
    },
    getListWardFailed(state) {
      state.loading = false
      state.listWard = []
    },
    unmountLocation(state) {
      state.listDistrict = []
      state.listProvince = []
      state.listWard = []
    },
    getListLyDoTuChoi(state, action) {
      state.loading = true
    },
    getListLyDoTuChoiSuccess(state, action) {
      state.listLyDoTuChoi = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLyDoTuChoiFailed(state) {
      state.loading = false
      state.listLyDoTuChoi = []
    },
    getListLoaiFile(state, action) {
      state.loading = true
    },
    getListLoaiFileSuccess(state, action) {
      state.listLoaiFile = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLoaiFileFailed(state) {
      state.loading = false
      state.listCauHinFilehHopDong = []
    },
    getListCauHinhLoaiFileHD(state, action) {
      state.loading = true
    },
    getListCauHinhLoaiFileHDSuccess(state, action) {
      const arrayCustom = action.payload.result?.map((item) => ({
        label:
          item?.loai === 'GIAYYEUCAUBOITHUONG'
            ? item.ten + ' - ' + 'Giấy yêu cầu bồi thường'
            : item.ten + '-' + item?.loai,
        value: item.id,
        extraData: {...item}, // Dùng dấu ngoặc nhọn để sao chép đối tượng
      }))

      state.listCauHinFilehHopDong = arrayCustom
      state.loading = false
    },
    getListCauHinhLoaiFileHDFailed(state) {
      state.loading = false
      state.listCauHinFilehHopDong = []
    },
    getListCauHinhDuyetHoSo(state, action) {
      state.loadingCauHinhDuyetHoSo = true
    },
    getListCauHinhDuyetHoSoSuccess(state, action) {
      state.listCauHinhDuyetHoSo = action.payload.results
      state.loadingCauHinhDuyetHoSo = false
      state.totalPageCauHinhDuyetHoSo = action.payload.total
    },
    getListCauHinhDuyetHoSoFailed(state) {
      state.loadingCauHinhDuyetHoSo = false
      state.listCauHinhDuyetHoSo = []
    },
    setObjectSearchListCauHinhDuyetHoSo(state, action) {
      state.objectSearchListCauHinhDuyetHoSo = {
        ...state.objectSearchListCauHinhDuyetHoSo,
        ...action.payload,
      }
    },
    getListTenCanBo(state, action) {
      state.loading = true
    },
    getListTenCanBoSuccess(state, action) {
      state.listTenCanBo = action.payload.result
      state.loading = false
    },
    getListTenCanBoFailed(state) {
      state.loading = false
      state.listTenCanBo = []
    },
    getListInsuranceCompanyWithFilter(state, action) {
      state.loading = true
    },
    getListInsuranceCompanyWithFilterSuccess(state, action) {
      // const hasTatCaOption = state.listInsuranceCompanyWithFilter.some(
      //   (item) => item.value === null
      // )

      // // Filter out new results that already exist in the current list (avoid duplicates)
      // const newResults = action.payload.results.filter(
      //   (newItem) =>
      //     !state.listInsuranceCompanyWithFilter.some(
      //       (existingItem) => existingItem.value === newItem.value
      //     )
      // )

      // state.listInsuranceCompanyWithFilter =
      //   action.payload.page === 0
      //     ? [
      //         {label: 'Tất cả', value: null}, // Add 'Tất cả' option at the top
      //         ...action.payload.results, // Replace with new values (no duplicates needed since page === 0)
      //       ]
      //     : [
      //         ...(hasTatCaOption ? [] : [{label: 'Tất cả', value: null}]), // Only add 'Tất cả' if it's not already in the list
      //         ...state.listInsuranceCompanyWithFilter, // Keep the old values
      //         ...newResults, // Add new values, excluding duplicates
      //       ]
      state.listInsuranceCompanyWithFilter = action.payload.results.map((item) => ({
        name: item.label,
        code: item.value,
      }))
      state.totalLengthListInsuranceCompanyWithFilter = action.payload.total
      state.loading = false
    },
    getListInsuranceCompanyWithFilterFailed(state) {
      state.loading = false
      state.listInsuranceCompanyWithFilter = []
    },
    setFilterListInsuranceCompany(state, action) {
      state.filterListInsuranceCompany = {...state.filterListInsuranceCompany, ...action.payload}
    },
    getListDanhMucThuoc(state, action) {
      state.loadingDanhSachDanhMucThuoc = true
    },
    getListDanhMucThuocSuccess(state, action) {
      state.listDanhMucThuoc = action.payload.results
      state.loadingDanhSachDanhMucThuoc = false
      state.totalPageDanhSachDanhMucThuoc = action.payload.total
    },
    getListDanhMucThuocFailed(state) {
      state.loadingDanhSachDanhMucThuoc = false
      state.listDanhMucThuoc = []
    },
    setObjectSearchListDanhMucThuoc(state, action) {
      state.objectSearchListDanhMucThuoc = {
        ...state.objectSearchListDanhMucThuoc,
        ...action.payload,
      }
    },
    getListCauHinhDongChiTra(state, action) {
      state.loadingCauHinhDongChiTra = true
    },
    getListCauHinhDongChiTraSuccess(state, action) {
      state.listCauHinhDongChiTra = action.payload.result
      state.loadingCauHinhDongChiTra = false
      state.totalPageCauHinhDongChiTra = action.payload.total
    },
    getListCauHinhDongChiTraFailed(state) {
      state.loadingCauHinhDongChiTra = false
      state.listCauHinhDongChiTra = []
    },
    getListCskcbBaoLanh(state,action){
      state.loading = true
    },
    getListCskcbBaoLanhSuccess(state,action){
      console.log(action,'fdfdfd');
      state.loading = false
      state.listCskcbBaoLanh = action.payload
      state.totalCountCskcbAllBaoLanh=action?.payload?.length
    },
    getListCskcbBaoLanhFailed(state,action){
      state.loading = false
      state.listCskcbBaoLanh = []
    },
    getListLyDoPheDuyet(state,action){
    },
    getListLyDoPheDuyetSuccess(state,action){
      state.listLyDoPheDuyet = action.payload.result
    },
    getListLyDoPheDuyetFailed(state,action){
      state.listLyDoPheDuyet = []
    },
  },
})
export const categoryActions = categorySlice.actions
const categoryReducer = categorySlice.reducer

export default categoryReducer

export const selectListCSKCB = (state) => state.category.ListCSKCB
export const selectListInsuranceCompany = (state) => state.category.ListInsuranceCompany
export const selectListProvince = (state) => state.category.listProvince
export const selectListDistrict = (state) => state.category.listDistrict
export const selectIdTinhThanh = (state) => state.category.idSelectedProvince
export const selectIdQuanHuyen = (state) => state.category.idSelectedDistrict
export const selectListWard = (state) => state.category.listWard
export const selectListLyDoTuChoi = (state) => state.category.listLyDoTuChoi
export const selectListLoaiFile = (state) => state.category.listLoaiFile
export const selectListCauHinhFileHD = (state) => state.category.listCauHinFilehHopDong
export const selectListDanhMucThuoc = (state) => state.category.listDanhMucThuoc
export const selectObjectSearchListDanhMucThuoc = (state) => state.category.objectSearchListDanhMucThuoc
export const selectLoadingDanhSachDanhMucThuoc = (state) => state.category.loadingDanhSachDanhMucThuoc
export const selectTotalPageDanhSachDanhMucThuoc= (state) => state.category.totalPageDanhSachDanhMucThuoc
export const selectListCauHinhDongChiTra= (state) => state.category.listCauHinhDongChiTra
export const selectTotalPageCauHinhDongChiTra = (state) => state.category.totalPageCauHinhDongChiTra
export const selectObjectSearchCauHinhDongChiTra = (state) => state.category.objectSearchCauHinhDongChiTra
export const selectLoadingCauHinhDongChiTra = (state) => state.category.loadingCauHinhDongChiTra
export const selectTotalCountCskcbAllBaoLanh = (state) => state.category.totalCountCskcbAllBaoLanh
export const selectListLyDoPheDuyet = (state) => state.category.listLyDoPheDuyet
