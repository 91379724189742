import { combineReducers } from "redux";
import commonReducer from "./common/commonSlice";
import searchFilterReducer from "../modules/proactive-appraisal/redux/searchFilter/searchFilterSlice";
import categoryReducer from "../modules/category/redux/category/categorySlice";
import permissionsReducer from "./permissions/permissionsSlice";
import contractReducer from "../modules/contract-customer/redux/contract/contractSlice";
import listContractReducer from "../modules/contract-customer/redux/listContract/listContractSlice";
import contractStepThirdReducer from "../modules/contract-customer/redux/contractStepThird/contractStepThirdSlice";
import contractStepTwoReducer from "../modules/contract-customer/redux/contractStepTwo/contractStepTwoSlice";
import contractStepOneReducer from "../modules/contract-customer/redux/contractStepOne/contractStepOneSlice";
import manualAssessmentReducer from "../modules/proactive-appraisal/redux/manualAssessment/manualAssessmentSlice";
import phieuDeNghiReducer from "app/modules/payment/redux/phieuDeNghi/phieuDeNghiSlice";
import traCuuReducer from "app/modules/tra-cuu/redux/traCuu/traCuuSlice";
import manageNewsEventsReducer from "app/modules/system/redux/manage-news-events/slice";
import dashboardReducer from "app/modules/statistic/redux/dashboard/dashboardSlice";
import mailboxReducer from "app/modules/system/redux/mailbox/mailboxSlice";
import infoChangeInsuredPersonReducer from "app/modules/contract-customer/redux/infoChangeInsuredPerson/infoChangeInsuredPersonSlice";

const rootReducer = combineReducers({
  common: commonReducer,
  searchFilter : searchFilterReducer,
  category : categoryReducer,
  permissions : permissionsReducer,
  contract : contractReducer,
  listContract: listContractReducer,
  contractStepThird:contractStepThirdReducer,
  contractStepTwo:contractStepTwoReducer,
  contractStepOne:contractStepOneReducer,
  manualAssessment : manualAssessmentReducer,
  phieuDeNghi:phieuDeNghiReducer,
  traCuu: traCuuReducer,
  manageNewsEvents: manageNewsEventsReducer,
  dashboard: dashboardReducer,
  mailbox: mailboxReducer,
  infoChangeInsuredPerson:infoChangeInsuredPersonReducer,
});
export default rootReducer;
