import {api} from 'app/config/api'
import axios from 'axios'

const header =
{
  headers: {
    'x-token-ocr': '123'
  }
}

export const getListEmail = (payload) => {
  const objString = '?' + new URLSearchParams(payload).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/fetch${objString}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDetailEmail = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/fetch?filter=message_id_hash:${payload}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDetailAttachments = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/get-attachments?email_id=${payload}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
