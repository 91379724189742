import {call, put, takeLatest, delay, select} from 'redux-saga/effects' // Import debounce from redux-saga
import {getDSLoaiNguoiDuocBH, getDSNganHang, getDanhSachCauHinhDongChiTra, getDanhSachNguoiDuocBaoHiem, getLoaiKhachHang} from './service'
import {handleError} from '_metronic/helpers'
import {contractStepThirdActions, selectIdHopDong} from './contractStepThirdSlice'
import { contractActions } from '../contract/contractSlice'

function* debounceSearchDanhSachNguoiDuocBaoHiem(action) {
  yield delay(500) // Đợi 300ms trước khi thực hiện tìm kiếm
  yield call(handleGetDanhSachNguoiDuocBaoHiem, {payload: action.payload})
}

// get danh sách hợp đồng
function* handleGetDanhSachNguoiDuocBaoHiem({payload}) {
  const idHopDong = yield select(selectIdHopDong)
  try {
    const resultDanhSachNguoiDuocBaoHiem = yield call(getDanhSachNguoiDuocBaoHiem, {
      data: payload,
      idHopDong,
    })
    console.log(resultDanhSachNguoiDuocBaoHiem);
    if (resultDanhSachNguoiDuocBaoHiem) {
      yield put({
        type: contractStepThirdActions.getListContractStepThirdSuccess.type,
        payload: resultDanhSachNguoiDuocBaoHiem,
      })
      if(resultDanhSachNguoiDuocBaoHiem?.results[0]){
      yield put({
        type: contractActions.setStageHopDong.type,
        payload: resultDanhSachNguoiDuocBaoHiem?.results[0]?.stageHopDong,
      })
      }
    }
  } catch (e) {
    yield put({
      type: contractStepThirdActions.getListContractStepThirdFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachLoaiKhachHang({payload}) {
  try {
    const resultDanhSachLoaiKhachHang = yield call(getLoaiKhachHang, payload)
    if (resultDanhSachLoaiKhachHang) {
      yield put({
        type: contractStepThirdActions.getDanhSachLoaiKhachHangSuccess.type,
        payload: resultDanhSachLoaiKhachHang,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepThirdActions.getDanhSachLoaiKhachHangFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachNganHang({payload}) {
  try {
    const result = yield call(getDSNganHang, payload)
    if (result) {
      yield put({
        type: contractStepThirdActions.getDanhSachNganHangSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepThirdActions.getDanhSachNganHangFailed.type,
    })
    handleError(e)
  }
}
function* handleGetDanhSachLoaiNguoiDuocBH({payload}) {
  try {
    const result = yield call(getDSLoaiNguoiDuocBH, payload)
    if (result) {
      yield put({
        type: contractStepThirdActions.getDanhSachLoaiNguoiDuocBHSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepThirdActions.getDanhSachLoaiNguoiDuocBHFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachCauHinhDongChiTra({payload}) {
  try {
    const result = yield call(getDanhSachCauHinhDongChiTra, payload)
    if (result) {
      yield put({
        type: contractStepThirdActions.getDanhSachCauHinhDongChiTraSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepThirdActions.getDanhSachCauHinhDongChiTraFailed.type,
    })
    handleError(e)
  }
}

export default function* contractStepThirdSaga() {
  yield takeLatest(
    contractStepThirdActions.getListContractStepThird.type,
    debounceSearchDanhSachNguoiDuocBaoHiem
  )
  yield takeLatest(
    contractStepThirdActions.getDanhSachLoaiKhachHang.type,
    handleGetDanhSachLoaiKhachHang
  )
  yield takeLatest(contractStepThirdActions.getDanhSachNganHang.type, handleGetDanhSachNganHang)
  yield takeLatest(contractStepThirdActions.getDanhSachLoaiNguoiDuocBH.type, handleGetDanhSachLoaiNguoiDuocBH)
  yield takeLatest(contractStepThirdActions.getDanhSachCauHinhDongChiTra.type, handleGetDanhSachCauHinhDongChiTra)

}
