import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loadingDanhSachCoSoKCBBL: false,
  loadingDanhSachCoSoKCBKTTBH: false,
  danhSachCoSoKCBBL: [],
  danhSachCoSoKCBKTTBH: [],
  totalPageDanhSachCoSoKCBBL: null,
  totalPageDanhSachCoSoKCBKTTBH: null,
  objectSearchDanhSachCoSoKCBBL: {
    page: 0,
    items_per_page: 10,
    searchMa: null,
    searchTen: null,
    searchDiaChi: null,
    searchTinhThanh: null,
  },
  objectSearchDanhSachCoSoKCBBL_default: {
    page: 0,
    items_per_page: 10,
    searchMa: null,
    searchTen: null,
    searchDiaChi: null,
    searchTinhThanh: null,
    searchBaoLanh:{label:'Tất cả' , value : null},
  },
  objectSearchDanhSachCoSoKCBKTTBH: {
    page: 0,
    items_per_page: 10,
    searchMa: null,
    searchTen: null,
    searchDiaChi: null,
    searchTinhThanh: null,
  },
  objectSearchDanhSachCoSoKCBKTTBH_default: {
    page: 0,
    items_per_page: 10,
    searchMa: null,
    searchTen: null,
    searchDiaChi: null,
    searchTinhThanh: null,
    searchKhongThanhToan:{label:'Tất cả' , value : null},
  },
  idHopDong: '',
  arraySelectIdsBaoLanh: [],
  arraySelectIdSBaoLanhDefault : [],
  countCSKCBBL:0,
  countCSKCBKTT:0,
}

export const contractStepTwoSlice = createSlice({
  name: 'contractStepTwo',
  initialState,
  reducers: {
    getDanhSachCoSoKCBBL(state, action) {
      state.loadingDanhSachCoSoKCBBL = true
    },
    getDanhSachCoSoKCBBLSuccess(state, action) {
      state.danhSachCoSoKCBBL = action.payload.results
      state.loadingDanhSachCoSoKCBBL = false
      state.totalPageDanhSachCoSoKCBBL = action.payload.total
      state.countCSKCBBL = action.payload.extraData.selectedCount
    },
    getDanhSachCoSoKCBBLFailed(state) {
      state.loadingDanhSachCoSoKCBBL = false
      state.danhSachCoSoKCBBL = []
    },
    setObjectSearchDanhSachCoSoKCBBL(state, action) {
      state.objectSearchDanhSachCoSoKCBBL = {
        ...state.objectSearchDanhSachCoSoKCBBL,
        ...action.payload,
      }
    },
    getDanhSachCoSoKCBKTTBH(state, action) {
      state.loadingDanhSachCoSoKCBKTTBH = true
    },
    getDanhSachCoSoKCBKTTBHSuccess(state, action) {
      state.danhSachCoSoKCBKTTBH = action.payload.results
      state.loadingDanhSachCoSoKCBKTTBH = false
      state.totalPageDanhSachCoSoKCBKTTBH = action.payload.total
      state.countCSKCBKTT = action.payload.extraData.selectedCount
    },
    getDanhSachCoSoKCBKTTBHFailed(state) {
      state.loadingDanhSachCoSoKCBKTTBH = false
      state.danhSachCoSoKCBKTTBH = []
    },
    setObjectSearchDanhSachCoSoKCBKTTBH(state, action) {
      state.objectSearchDanhSachCoSoKCBKTTBH = {
        ...state.objectSearchDanhSachCoSoKCBKTTBH,
        ...action.payload,
      }
    },
    setIdHopDong(state, action) {
      state.idHopDong = action.payload
    },
    setArrayBaoLanhIds(state, action) {
      const newArrayMerge = [...new Set([...state.arraySelectIdsBaoLanh, ...action.payload])]
      const uniqueObjects = Array.from(new Set(newArrayMerge.map((obj) => obj.idCoSoKCB))).map(
        (id) => {
          return newArrayMerge.find((obj) => obj.idCoSoKCB === id)
        }
      )
      state.arraySelectIdsBaoLanh = uniqueObjects
    },
    setArrayBaoLanhIdsDefault(state, action) {
      const newArrayMerge = [...new Set([...state.arraySelectIdSBaoLanhDefault, ...action.payload])]
      const uniqueObjects = Array.from(new Set(newArrayMerge.map((obj) => obj.idCoSoKCB))).map(
        (id) => {
          return newArrayMerge.find((obj) => obj.idCoSoKCB === id)
        }
      )
      state.arraySelectIdSBaoLanhDefault = uniqueObjects
    },
  },
})
export const contractStepTwoActions = contractStepTwoSlice.actions
const contractStepTwoReducer = contractStepTwoSlice.reducer

export default contractStepTwoReducer

export const selectDanhSachCoSoKCBBL = (state) => state.contractStepTwo.danhSachCoSoKCBBL

export const selectTotalDanhSachCoSoKCBBL = (state) =>
  state.contractStepTwo.totalPageDanhSachCoSoKCBBL
export const selectLoadingDanhSachCoSoKCBBL = (state) =>
  state.contractStepTwo.loadingDanhSachCoSoKCBBL
export const selectObjectSearchDanhSachCoSoKCBBL = (state) =>
  state.contractStepTwo.objectSearchDanhSachCoSoKCBBL
  export const selectObjectSearchDanhSachCoSoKCBBLDefault = (state) => state.contractStepTwo.objectSearchDanhSachCoSoKCBBL_default

export const selectDanhSachCoSoKCBKTTBH = (state) => state.contractStepTwo.danhSachCoSoKCBKTTBH

export const selectTotalDanhSachCoSoKCBKTTBH = (state) =>
  state.contractStepTwo.totalPageDanhSachCoSoKCBKTTBH
export const selectLoadingDanhSachCoSoKCBKTTBH = (state) =>
  state.contractStepTwo.loadingDanhSachCoSoKCBKTTBH
export const selectObjectSearchDanhSachCoSoKCBKTTBH = (state) =>
  state.contractStepTwo.objectSearchDanhSachCoSoKCBKTTBH
  export const selectObjectSearchDanhSachCoSoKCBKTTBHDefault = (state) =>
  state.contractStepTwo.objectSearchDanhSachCoSoKCBKTTBH_default
export const selectArraySelectIdsBaoLanh = (state) => state.contractStepTwo.arraySelectIdsBaoLanh
export const selectArraySelectIdsBaoLanhDefault = (state) => state.contractStepTwo.arraySelectIdSBaoLanhDefault

export const selectIdHopDong = (state) => state.contractStepThird.idHopDong
export const selectCountCSKCBBL = (state) => state.contractStepTwo.countCSKCBBL
export const selectCountCSKCBKTT = (state) => state.contractStepTwo.countCSKCBKTT