import {call, put, takeLatest} from 'redux-saga/effects' // Import debounce from redux-saga
import { listContractActions } from './listContractSlice' 
import {
  getListContract,
  getListContractByCongTyBH,
} from './service'
import {handleError} from '_metronic/helpers'

function* debounceSearchContract(action) {
  yield call(handleGetListContract, {payload: action.payload})
}

// get danh sách hợp đồng
function* handleGetListContract({payload}) {
  try {
    const resultsListContract = yield call(getListContract, payload)
    if (resultsListContract) {
      yield put({
        type: listContractActions.getListContractSuccess.type,
        payload: resultsListContract,
      })
      // const ids = resultsListMedicalRecord?.results
      //   ?.filter((item: any) => item.hasOwnProperty('id'))
      //   .map((item: any) => item.id)
      // yield put({
      //   type: searchFilterActions.setObjectPrevFowardId.type,
      //   payload: {
      //     page: payload?.page / payload?.items_per_page,
      //     items_per_page: payload?.items_per_page,
      //     listId: ids,
      //   },
      // })
    }
  } catch (e) {
    yield put({
      type: listContractActions.getListContractFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListContractByIdCongTyBaoHiem({payload}) {
  try {
    const resultsListContractByCongTyBH = yield call(getListContractByCongTyBH, payload)
    if (resultsListContractByCongTyBH) {
      yield put({
        type: listContractActions.getListContractByCongTyBHSuccess.type,
        payload: resultsListContractByCongTyBH,
      })
    }
  } catch (e) {
    yield put({
      type: listContractActions.getListContractByCongTyBHFailed.type,
    })
    handleError(e)
  }
}

export default function* listContractSaga() {
  yield takeLatest(listContractActions.getListContract.type, debounceSearchContract)
  yield takeLatest(listContractActions.getListContractByCongTyBH.type, handleGetListContractByIdCongTyBaoHiem)
}
