import {handleError, toAbsoluteUrl} from '_metronic/helpers'
import LoadingIndicator from 'app/components/loading'
import ToastMessage from 'app/components/ToastMessage'
import {api} from 'app/config/api'
import axios from 'axios'
import {useState} from 'react'
import {Modal} from 'react-bootstrap'

function PopupWarning(props: {
  show: boolean
  onAgree?: any
  title?: string
  onHide: any
  subTitle?: string
  textAgree?: string
  isVuotHanMuc?: boolean
  isVuotTime?: boolean
  dataValidationTime?: any
  textDecline?: string
  isLoading?: boolean
  loadingBtnSuccess?: boolean
  type?: string
  validationTienChuyenDuyet?: any
  setValidationTienChuyenDuyet?: any
  typeChuyenDuyet?: any
  selectedUserApprove?: any
  id?: any
  setShowNavigate?: any
  values?: any
  getDetailHSTTT?: any
  isHoSoBaoLanh?: any
}) {
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<any>(false)
  const updateAction = () => {
    console.log('chitiethoantra')
    setIsLoadingUpdate(true)
    axios
      .put(`${api.API_HOSO_TTTT}/${props.id}?force=true`, props.values)
      .then((data) => {
        console.log(data, 'data')
        if (data) {
          try {
            console.log('chuyenduyet')
            const response: any = axios.patch(
              `${api.API_HOSO_TTTT}/${props.id}/nguoi-duyet/${props.selectedUserApprove.value}`
            )
            if (response) {
              ToastMessage('success', 'Chuyển duyệt hồ sơ thành công')
              setTimeout(() => {
                props.onHide()
                props.setShowNavigate(false)
                props.getDetailHSTTT()
              }, 500)
            }
          } catch (error) {
            // Handle any error (from onAgree or API call)
            handleError(error)
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingUpdate(false)
      })
  }

  return (
    <Modal centered show={props?.show} onHide={() => {}}>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 10,
          }}
          className='row'
        >
          <div
            style={{
              width: 70,
              height: 70,
              borderRadius: 40,
              backgroundColor: '#FCEEED',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            <img src={toAbsoluteUrl('/media/common/icon_warning.svg')} />
          </div>
          <span style={{fontSize: 20, fontWeight: 'bold', textAlign: 'center', paddingBottom: 5}}>
            {props.title || ''}
          </span>
          <span
            style={{
              fontSize: 14,
              textAlign: 'center',
              paddingBottom: props?.isVuotTime ? 10 : 20,
              width: '80%',
            }}
          >
            {props.subTitle || ''}
          </span>
          <span style={{fontSize: 14}}>
            {props?.isVuotTime && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  textAlign: 'left',
                  marginBottom: '10px',
                  paddingLeft: '9em',
                  paddingBottom: '20px',
                }}
              >
                {console.log(props?.dataValidationTime)}
                {Object.entries(props?.dataValidationTime || {}).map(([key, value]) => {
                  // Kiểm tra nếu giá trị không phải là null, undefined hoặc empty
                  if (value) {
                    return (
                      <span key={key}>
                        <li>{value}</li>
                      </span>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </span>
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-secondary'
              style={{marginRight: 10, width: 179, borderRadius: '10px'}}
              onClick={() => {
                props.onHide()
                if (props.isHoSoBaoLanh) props.setValidationTienChuyenDuyet()
              }}
            >
              {props?.textDecline ? props?.textDecline : 'Đóng'}
            </button>
            {!props.validationTienChuyenDuyet ? (
              props?.isLoading === true ? (
                !props?.loadingBtnSuccess ? (
                  <button
                    style={{
                      width: 179,
                      backgroundColor: '#FC6B03',
                      borderRadius: '10px',
                      color: 'white',
                    }}
                    className='btn '
                    onClick={() => {
                      console.log(props, 'propassasa')
                      if (props.typeChuyenDuyet) {
                        console.log('22323aggg')
                        updateAction()
                      } else {
                        props.onAgree()
                        props.onHide()
                      }
                    }}
                  >
                    {props?.textAgree}
                  </button>
                ) : (
                  <button
                    style={{
                      width: 179,
                      backgroundColor: '#FC6B03',
                      borderRadius: '10px',
                      color: 'white',
                    }}
                    className='btn '
                  >
                    <LoadingIndicator />
                  </button>
                )
              ) : !props.isVuotHanMuc && props?.type !== 'ho_so' ? (
                !isLoadingUpdate ? (
                  <button
                    style={{
                      width: 179,
                      backgroundColor: '#FC6B03',
                      borderRadius: '10px',
                      color: 'white',
                    }}
                    className='btn '
                    onClick={() => {
                      console.log(props, 'propsaasas')
                      if (props.typeChuyenDuyet) {
                        console.log('22323aggg')
                        updateAction()
                      } else {
                        props.onAgree()
                        props.onHide()
                      }
                    }}
                  >
                    {props?.textAgree}
                  </button>
                ) : (
                  <button
                    style={{
                      width: 179,
                      backgroundColor: '#FC6B03',
                      borderRadius: '10px',
                      color: 'white',
                    }}
                    className='btn '
                  >
                    <LoadingIndicator />
                  </button>
                )
              ) : null
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PopupWarning
