import {call, put, takeLatest} from 'redux-saga/effects'
import {infoChangeInsuredPersonActions} from './infoChangeInsuredPersonSlice'
import {handleError} from '_metronic/helpers'
import {functionGetListCapDoHanMuc, functionGetListContractByCongTyBH, getListContractByCongTyBH, getListInfoChangeInsuredPersonActions} from './service'

function* handleGetListInfoChangeInsuredPerson({payload}) {
  try {
    const result = yield call(getListInfoChangeInsuredPersonActions, payload)
    if (result) {
      yield put({
        type: infoChangeInsuredPersonActions.getListInfoChangeInsuredPersonSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: infoChangeInsuredPersonActions.getListInfoChangeInsuredPersonFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListContractByIdCongTyBaoHiem({payload}) {
  try {
    const resultsListContractByCongTyBH = yield call(functionGetListContractByCongTyBH, payload)
    if (resultsListContractByCongTyBH) {
      yield put({
        type: infoChangeInsuredPersonActions.getListContractByCongTyBHSuccess.type,
        payload: resultsListContractByCongTyBH,
      })
    }
  } catch (e) {
    yield put({
      type: infoChangeInsuredPersonActions.getListContractByCongTyBHFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListCapDoHanMuc({payload}) {
  try {
    const resultsListCapDo = yield call(functionGetListCapDoHanMuc, payload)
    if (resultsListCapDo) {
      yield put({
        type: infoChangeInsuredPersonActions.getListCapDoHanMucSuccess.type,
        payload: resultsListCapDo,
      })
    }
  } catch (e) {
    yield put({
      type: infoChangeInsuredPersonActions.getListCapDoHanMucFailed.type,
    })
    handleError(e)
  }
}

export default function* infoChangeInsuredPersonSaga() {
  yield takeLatest(
    infoChangeInsuredPersonActions.getListInfoChangeInsuredPerson.type,
    handleGetListInfoChangeInsuredPerson
  )
  yield takeLatest(
    infoChangeInsuredPersonActions.getListContractByCongTyBH.type,
    handleGetListContractByIdCongTyBaoHiem
  )
  yield takeLatest(
    infoChangeInsuredPersonActions.getListCapDoHanMuc.type,
    handleGetListCapDoHanMuc
  )
}
