import { createSlice } from '@reduxjs/toolkit'
import { getListCauHinhLoaiFileHD } from './service'

const initialState = {
  mailSelected: null,
  listMailbox: [],
  loadingListMailbox: false,
  detailMail: null,
  loadingDetailMail: false,
  objectSearch: {
    limit: 10,
    page: 1
  },
  detailAttachments: null,
  loadingDetailAttachments: false,
}

export const mailboxSlice = createSlice({
  name: 'mailbox',
  initialState,
  reducers: {
    setListMailbox(state, action) {
      state.listMailbox = action.payload
    },
    setMailSelected(state, action) {
      state.mailSelected = action.payload
    },
    getListMailbox(state, action) {
      state.loadingListMailbox = true
    },
    getListMailboxSuccess(state, action) {
      state.listMailbox = action.payload.results
      state.loadingListMailbox = false
    },
    getListMailFailed(state, action) {
      state.listMailbox = []
      state.loadingListMailbox = false
    },
    getDetailEmail(state, action) {
      state.loadingDetailMail = true
    },
    getDetailEmailSuccess(state, action) {
      state.loadingDetailMail = false
      state.detailMail = action.payload.results
    },
    getDetailEmailFailed(state, action) {
      state.loadingDetailMail = false
      state.detailMail = null
    },
    setObjectSearch(state, action) {
      state.objectSearch = { ...state.objectSearch, ...action.payload }
    },
    getDetailAttachments(state, action) {
      state.loadingDetailAttachments = true
    },
    getDetailAttachmentsSuccess(state, action) {
      state.loadingDetailAttachments = false
      state.detailAttachments = action.payload.results
    },
    getDetailAttachmentsFailed(state, action) {
      state.loadingDetailAttachments = false
      state.detailAttachments = null
    },
    setObjectSearchDefault(state) {
      state.objectSearch = initialState.objectSearch
    }
  },
})

export const mailboxActions = mailboxSlice.actions
const mailboxReducer = mailboxSlice.reducer

export default mailboxReducer

export const selectListMailbox = (state) => state.mailbox.listMailbox
export const selectMailSelected = (state) => state.mailbox.mailSelected
export const selectLoadingListMailbox = (state) => state.mailbox.loadingListMailbox
export const selectObjectSearch = (state) => state.mailbox.objectSearch
export const selectDetailAttachments  = (state) => state.mailbox.detailAttachments
export const selectLoadingDetailAttachments  = (state) => state.mailbox.loadingDetailAttachments
export const selectDetailMail  = (state) => state.mailbox.detailMail
export const selectLoadingDetailMail = (state) => state.mailbox.loadingDetailMail


