import React, { useEffect, useState } from "react";
import DatePicker, { DatePickerProps } from "react-datepicker";
import { toZonedTime, fromZonedTime } from "date-fns-tz";

const timeZone = "Asia/Ho_Chi_Minh";

interface CustomDatePicker {
  onChangeCustom: (utcMilliseconds: number | null) => void; // Callback được truyền vào
}

type CustomDatePickerProps = DatePickerProps & CustomDatePicker;

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  onChangeCustom,
  selected,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  // Đồng bộ hóa state nội bộ với props.selected khi giá trị props thay đổi
  useEffect(() => {
    if (selected) {
      // Kiểm tra nếu `selected` là kiểu `number` (milliseconds)
      const date =
        typeof selected === "number" || typeof selected === "string"
          ? new Date(+selected) // Chuyển `string` hoặc `number` thành `Date`
          : selected; // Nếu đã là `Date` thì giữ nguyên
  
      const zonedDate = toZonedTime(date, timeZone);
      setSelectedDate(zonedDate);
    } else {
      setSelectedDate(null);
    }
  }, [selected]);

  // Hiển thị ngày theo múi giờ
  const displayDate = selectedDate;

  const displayMinDate = props?.minDate ? toZonedTime(new Date(props?.minDate), timeZone) : undefined;

  const displayMaxDate = props?.maxDate ? toZonedTime(new Date(props?.maxDate), timeZone) : undefined;

  const handleChange = (
    date: Date | null,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    if (!date) {
      setSelectedDate(null);
      onChangeCustom(null);
      return;
    }

    const utcDate = fromZonedTime(date, timeZone);
    const utcMilliseconds = utcDate.getTime();
    setSelectedDate(utcDate); // Cập nhật state nội bộ
    onChangeCustom(utcMilliseconds); // Gửi callback lên component cha
  };

  return (
    <DatePicker
      {...props}
      selected={displayDate}
      onChange={handleChange}
      minDate={displayMinDate}
      maxDate={displayMaxDate}
    />
  );
};

export default CustomDatePicker;
