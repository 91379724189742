import clsx from 'clsx'
import { ToolbarType, useLayout } from '../../core'
import { Toolbar } from './Toolbar'
import { PageTitleWrapper } from './page-title'
import './toolbar.scss'
import { useSelector } from 'react-redux'
import { selectShowBreadcrumb, selectShowMenu } from 'app/redux/common/commonSlice'
const ToolbarWrapper = (props: any) => {
  const { config, classes } = useLayout()
  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  return (
    !props?.showBreadcrumb ? (
      <div
        id='kt_app_toolbar'
        className={clsx('app-toolbar', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
      >
        <div
          id='kt_app_toolbar_container'
          className={clsx(
            'breadcrumbs',
            'app-container',
            classes.toolbarContainer.join(' '),
            config.app?.toolbar?.containerClass,
            config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
            {
              'container-fluid': config.app?.toolbar?.container === 'fluid',
              'container-xxl': config.app?.toolbar?.container === 'fixed',
            }
          )}
          style={{ justifyContent: 'unset', display: 'block' }}
        >
          <Toolbar />
          {isPageTitleVisible && <PageTitleWrapper />}
        </div>
      </div>
    ) : null // or use <></> for an empty fragment
  );
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export { ToolbarWrapper }
