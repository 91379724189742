import { call, put, takeEvery } from "redux-saga/effects";
import { mailboxActions } from "./mailboxSlice";
import { getDetailAttachments, getDetailEmail, getListEmail } from "./service";
import { handleError } from "_metronic/helpers";


function* handleGetListEmail({ payload }) {
  try {
    const results = yield call(getListEmail, payload);
    if (results.data && results.data?.length > 0) {
      yield put({
        type: mailboxActions.getListMailboxSuccess.type,
        payload: { results: results.data },
      });
    }else{
      yield put({
        type: mailboxActions.getListMailFailed.type,
      });
    }
  } catch (e) {
    yield put({
      type: mailboxActions.getListMailFailed.type,
    });
    handleError(e)
  }
}



function* handleGetDetailEmail({ payload }) {
  try {
    const results = yield call(getDetailEmail, payload);
    if (results.data) {
      yield put({
        type: mailboxActions.getDetailEmailSuccess.type,
        payload: { results: results.data[0] },
      });
    }
  } catch (e) {
    yield put({
      type: mailboxActions.getDetailEmailFailed.type,
    });
    handleError(e)
  }
}

function* handleGetDetailAttachments({ payload }) {
  try {
    const results = yield call(getDetailAttachments, payload);
    if (results && results?.length >0) {
      const resultsTemp = results.map(element => {
        return(
          {
            ...element,
            label: element?.filename,
            value: element?.attachment_id
          }
        )
      });
      yield put({
        type: mailboxActions.getDetailAttachmentsSuccess.type,
        payload: { results: resultsTemp },
      });
    }else{
       yield put({
      type: mailboxActions.getDetailAttachmentsFailed.type,
    });
    }
  } catch (e) {
    yield put({
      type: mailboxActions.getDetailAttachmentsFailed.type,
    });
    handleError(e)
  }
}

export default function* mailboxSaga() {
  yield takeEvery(mailboxActions.getListMailbox.type, handleGetListEmail);
  yield takeEvery(mailboxActions.getDetailEmail.type, handleGetDetailEmail);
  yield takeEvery(mailboxActions.getDetailAttachments.type, handleGetDetailAttachments);
}
